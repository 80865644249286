import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './Context/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const PrivateRoutes: React.FC = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginLeft: '16px' }}>
          Reloading...
        </Typography>
      </div>
    );
  }

  if (user) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
