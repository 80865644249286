import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseNavConfig = {
  apiKey: "AIzaSyB5OjYS2JQEQOOoFbp4YYrZ01ieRgeZ4Zo",
  authDomain: "logisitc-tiloli-packing-list.firebaseapp.com",
  projectId: "logisitc-tiloli-packing-list",
  storageBucket: "logisitc-tiloli-packing-list.appspot.com",
  messagingSenderId: "989696740923",
  appId: "1:989696740923:web:a00995e8bf229766fc961e"
};

const firebaseNavApp = initializeApp(firebaseNavConfig, "navApp");
const functionsNav = getFunctions(firebaseNavApp, 'europe-west3');


if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functionsNav, "localhost", 5001);  
}


export default functionsNav;