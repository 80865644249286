import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { useNavigate, Navigate } from 'react-router-dom';
import { Container, Box, Button, Snackbar, Paper, CircularProgress, Typography, AppBar, Toolbar, Alert } from '@mui/material';
import { useAuth } from './Context/AuthContext'; 
import app from './back/firestoreAuthConfig';

const LoginPage: React.FC = () => {
    const auth = getAuth(app);
    const navigate = useNavigate();
    const { login, user, loading } = useAuth();
    const [error, setError] = useState<string>("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/app');
        }
    }, [user, navigate]);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            login(userData);
        }
    }, [login]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    textAlign: 'center',
                }}
            >
                <CircularProgress />
                <Typography variant="h6" sx={{ ml: 2 }}>
                    Reloading...
                </Typography>
            </Box>
        );
    }

    if (user) {
        return <Navigate to="/app" />;
    }

    const handleMicrosoftLogin = async () => {
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: process.env.REACT_APP_TENANT_ID || "",
        });
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            if (user.email !== null) {
                login({
                    uid: user.uid,
                    email: user.email,
                    displayName: user.displayName || "",
                    lastSignInTime: user.metadata.lastSignInTime || "", 
                    photoURL: user.photoURL || null,
                });
                navigate('/app');
            } else {
                setError("Unauthorized email.");
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error("Error signing in with Microsoft:", error);
            setError("Error signing in with Microsoft");
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar position="static" sx={{ backgroundColor: '#E4007C', width: '100%' }}>
                <Toolbar sx={{ padding: '10px 20px', display: 'flex', justifyContent: 'center' }}>
                    <Box display="flex" alignItems="center">
                        <img src="/tiloliLogo.png" alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            Packing List Generator
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>

            <Container component="main" maxWidth="xs" sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <Paper elevation={3} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button onClick={handleMicrosoftLogin} variant="contained" color="primary">
                            Login with Microsoft
                        </Button>
                    </Paper>
                </Box>
            </Container>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert elevation={6} variant="filled" severity="error" onClose={handleCloseSnackbar}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default LoginPage;
