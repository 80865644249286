import React, { createContext, useEffect, useContext, useState, ReactNode, useCallback } from 'react';
import { signOut, onAuthStateChanged, getAuth, User } from 'firebase/auth';
import app from '../back/firestoreAuthConfig';

interface IUser {
    uid: string;
    email: string | null;
    displayName: string;
    lastSignInTime: string;
    photoURL: string | null; 
}
interface AuthContextType {
  user: IUser | null;
  loading: boolean;
  login: (userData: IUser | null) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}


export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app);
  
  const login = (userData: IUser | null) => {
    console.log('[AuthContext] login - Setting user:', userData);
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = useCallback(() => {
    console.log('[AuthContext] logout - Logging out user');
    signOut(auth);
    setUser(null);
    localStorage.removeItem('user');
  }, [auth]);

  useEffect(() => {
    console.log('[AuthContext] useEffect - Checking localStorage for user data');
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      console.log('[AuthContext] useEffect - Setting user from localStorage:', userData);
      login(userData);
    }

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser: User | null) => {
      console.log('[AuthContext] onAuthStateChanged - Firebase user state:', firebaseUser);
      if (firebaseUser) {
        const adaptedUser: IUser = {
          uid: firebaseUser.uid,
          displayName: firebaseUser.displayName || '',
          email: firebaseUser.email || null,
          lastSignInTime: firebaseUser.metadata.lastSignInTime || '',
          photoURL: firebaseUser.photoURL || null,
        };
        console.log('[AuthContext] onAuthStateChanged - Adapting user:', adaptedUser);
        login(adaptedUser);
      } else {
        logout();
      }
      setLoading(false);
    });

    return () => {
      console.log('[AuthContext] Cleanup - Unsubscribing from onAuthStateChanged');
      unsubscribe();
    };
  }, [auth, logout]);

  return (
    <AuthContext.Provider value={{ user, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
