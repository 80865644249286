import { useState } from 'react';
import {Product} from './interfaces';
interface DocumentData {
  format: 'pdf' | 'excel';
  data: {
    totalWeight: number;
    destinationNo: string;
    userInitials: string;
    date: string;
    showUnitCost: boolean;
    allContent: {
      cartonLists: CartonList[];
      paletteLists: PaletteList[];
    };
  };
}

interface CartonList { name: string, items: Product[], totalPoidsCarton?: number; };

interface PaletteList {
  name: string;
  items: (CartonList | Product)[];
}



export const DocumentGenerator = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const apiUrl = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL_PROD
  : process.env.REACT_APP_API_URL_DEV;

  const generateDocument = async (documentData: DocumentData) => {
    setIsLoading(true);
    setError(null);

    console.log("mon apiurl", apiUrl)
    if (!apiUrl) {
        setError("L'URL de l'API n'est pas définie.");
        setIsLoading(false);
        return;
      }
  
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(documentData),
            mode: 'cors'
          });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();

      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = `${documentData.data.destinationNo}_${documentData.data.date}_GlobalPackingList`;
      
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      if (!filename.endsWith('.pdf') && !filename.endsWith('.xlsx')) {
        filename += documentData.format === 'pdf' ? '.pdf' : '.xlsx';
      }

      if (documentData.format === 'pdf') {
        return blob;
      } else {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
    } catch (err) {
      console.error('Error:', err);
      setError('Une erreur est survenue lors de la génération du document.');
    } finally {
      setIsLoading(false);
    }
  };

  return { generateDocument, isLoading, error };
};