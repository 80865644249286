import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Product, WarehouseHeader } from './interfaces';
type Carton = { name: string, items: Product[],totalPoidsCarton?: number };
// type Palette = { name: string, items: (Product | Carton)[] };

interface AppContextProps {
  items: Product[];
  setItems: React.Dispatch<React.SetStateAction<Product[]>>;
  headers: WarehouseHeader[];
  setHeaders: React.Dispatch<React.SetStateAction<WarehouseHeader[]>>;
  showAlert: boolean;
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>;
  alertMessage: string;
  setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
  orderBy: keyof Product;
  setOrderBy: React.Dispatch<React.SetStateAction<keyof Product>>;
  order: 'asc' | 'desc';
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  blNumber: string;
  setBlNumber: React.Dispatch<React.SetStateAction<string>>;
  blList: string[];
  setBlList: React.Dispatch<React.SetStateAction<string[]>>;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  deleteItem: Product | null;
  setDeleteItem: React.Dispatch<React.SetStateAction<Product | null>>;
  quantityToDelete: number;
  setQuantityToDelete: React.Dispatch<React.SetStateAction<number>>;
  openDeleteModal: boolean;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  warehouseHeaders: WarehouseHeader[];
  setWarehouseHeaders: React.Dispatch<React.SetStateAction<WarehouseHeader[]>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  cartonLists: { name: string, items: Product[]; totalPoidsCarton?: number; }[];
  setCartonLists: React.Dispatch<React.SetStateAction<{ name: string, items: Product[], totalPoidsCarton?: number; }[]>>;
  paletteLists: { name: string, items: (Product | { name: string, items: Product[],totalPoidsCarton?: number })[] }[];
  setPaletteLists: React.Dispatch<React.SetStateAction<{ name: string, items: (Product | { name: string, items: Product[],totalPoidsCarton?: number })[] }[]>>;
  combinedList: string[];
  setCombinedList: React.Dispatch<React.SetStateAction<string[]>>;
  receivedData: any;
  setReceivedData: React.Dispatch<React.SetStateAction<any>>;
  sortedItems: Product[];
  setSortedItems: React.Dispatch<React.SetStateAction<Product[]>>;
  removedItems: Product[];
  setRemovedItems: React.Dispatch<React.SetStateAction<Product[]>>;
  allContent: {
    cartonLists: { name: string, items: Product[],totalPoidsCarton?: number; }[];
    paletteLists: { name: string, items: (Product | { name: string, items: Product[],totalPoidsCarton?: number })[] }[];
  };
  setAllContent: React.Dispatch<React.SetStateAction<{
    cartonLists: { name: string, items: Product[]; totalPoidsCarton?: number;}[];
    paletteLists: { name: string, items: (Product | { name: string, items: Product[],totalPoidsCarton?: number })[] }[];
  }>>;
  onDeleteProduct: { id: string, quantity: number } | null;
  handleReset: (closeModal: () => void) => void;
  handleDataReceived: (data: any) => void;
  setOnDeleteProduct: React.Dispatch<React.SetStateAction<{ id: string; quantity: number } | null>>;
  DeletionProductInCarton: { id: string; quantity: number } | null;
  setDeletionProductInCarton: React.Dispatch<React.SetStateAction<{ id: string; quantity: number } | null>>;
  selectedCarton: Carton | null;
  setSelectedCarton: (carton: Carton | null) => void;
  totalItems: number;
  setTotalItems: React.Dispatch<React.SetStateAction<number>>;
  modalWeight: string;
  setModalWeight: React.Dispatch<React.SetStateAction<string>>;
  showUnitCost: boolean;
  setShowUnitCost: React.Dispatch<React.SetStateAction<boolean>>;
  logContextState: () => void; 
  poidsGlobalTotal: number;
  setPoidsGlobalTotal: React.Dispatch<React.SetStateAction<number>>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [items, setItems] = useState<Product[]>(() => {
    const storedItems = localStorage.getItem('items');
    return storedItems ? JSON.parse(storedItems) : [];
  });
  const [headers, setHeaders] = useState<WarehouseHeader[]>(() => {
    const storedHeaders = localStorage.getItem('headers');
    return storedHeaders ? JSON.parse(storedHeaders) : [];
  });
  const [showAlert, setShowAlert] = useState<boolean>(() => {
    const storedShowAlert = localStorage.getItem('showAlert');
    return storedShowAlert ? JSON.parse(storedShowAlert) : false;
  });
  const [alertMessage, setAlertMessage] = useState<string>(() => {
    const storedAlertMessage = localStorage.getItem('alertMessage');
    return storedAlertMessage || '';
  });
  const [orderBy, setOrderBy] = useState<keyof Product>(() => {
    const storedOrderBy = localStorage.getItem('orderBy');
    return storedOrderBy ? (storedOrderBy as keyof Product) : 'RefTILOLI';
  });
  const [order, setOrder] = useState<'asc' | 'desc'>(() => {
    const storedOrder = localStorage.getItem('order');
    return storedOrder ? (storedOrder as 'asc' | 'desc') : 'asc';
  });
  const [progress, setProgress] = useState<number>(() => {
    const storedProgress = localStorage.getItem('progress');
    return storedProgress ? parseFloat(storedProgress) : 0;
  });
  const [blNumber, setBlNumber] = useState<string>(() => {
    const storedBlNumber = localStorage.getItem('blNumber');
    return storedBlNumber || '';
  });
  const [blList, setBlList] = useState<string[]>(() => {
    const storedBlList = localStorage.getItem('blList');
    return storedBlList ? JSON.parse(storedBlList) : [];
  });
  const [errorMessage, setErrorMessage] = useState<string>(() => {
    const storedErrorMessage = localStorage.getItem('errorMessage');
    return storedErrorMessage || '';
  });
  const [deleteItem, setDeleteItem] = useState<Product | null>(() => {
    const storedDeleteItem = localStorage.getItem('deleteItem');
    return storedDeleteItem ? JSON.parse(storedDeleteItem) : null;
  });
  const [quantityToDelete, setQuantityToDelete] = useState<number>(() => {
    const storedQuantityToDelete = localStorage.getItem('quantityToDelete');
    return storedQuantityToDelete ? parseInt(storedQuantityToDelete, 10) : 1;
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(() => {
    const storedOpenDeleteModal = localStorage.getItem('openDeleteModal');
    return storedOpenDeleteModal ? JSON.parse(storedOpenDeleteModal) : false;
  });
  const [warehouseHeaders, setWarehouseHeaders] = useState<WarehouseHeader[]>(() => {
    const storedWarehouseHeaders = localStorage.getItem('warehouseHeaders');
    return storedWarehouseHeaders ? JSON.parse(storedWarehouseHeaders) : [];
  });
  const [openModal, setOpenModal] = useState<boolean>(() => {
    const storedOpenModal = localStorage.getItem('openModal');
    return storedOpenModal ? JSON.parse(storedOpenModal) : false;
  });
  const [cartonLists, setCartonLists] = useState<{ name: string; items: Product[] }[]>(() => {
    const storedCartonLists = localStorage.getItem('cartonLists');
    return storedCartonLists ? JSON.parse(storedCartonLists) : [];
  });
  const [paletteLists, setPaletteLists] = useState<{ name: string; items: (Product | { name: string, items: Product[] })[] }[]>(() => {
    const storedPaletteLists = localStorage.getItem('paletteLists');
    return storedPaletteLists ? JSON.parse(storedPaletteLists) : [];
  });
  const [combinedList, setCombinedList] = useState<string[]>(() => {
    const storedCombinedList = localStorage.getItem('combinedList');
    return storedCombinedList ? JSON.parse(storedCombinedList) : [];
  });
  const [receivedData, setReceivedData] = useState<any>(() => {
    const storedReceivedData = localStorage.getItem('receivedData');
    return storedReceivedData ? JSON.parse(storedReceivedData) : null;
  });
  const [sortedItems, setSortedItems] = useState<Product[]>(() => {
    const storedSortedItems = localStorage.getItem('sortedItems');
    return storedSortedItems ? JSON.parse(storedSortedItems) : [];
  });
  const [removedItems, setRemovedItems] = useState<Product[]>(() => {
    const storedRemovedItems = localStorage.getItem('removedItems');
    return storedRemovedItems ? JSON.parse(storedRemovedItems) : [];
  });
  const [allContent, setAllContent] = useState<{
    cartonLists: { name: string; items: Product[] }[];
    paletteLists: { name: string; items: (Product | { name: string; items: Product[] })[] }[];
  }>(() => {
    const storedAllContent = localStorage.getItem('allContent');
    return storedAllContent ? JSON.parse(storedAllContent) : { cartonLists: [], paletteLists: [] };
  });
  const [onDeleteProduct, setOnDeleteProduct] = useState<{ id: string; quantity: number } | null>(() => {
    const storedOnDeleteProduct = localStorage.getItem('onDeleteProduct');
    return storedOnDeleteProduct ? JSON.parse(storedOnDeleteProduct) : null;
  });

  const [DeletionProductInCarton, setDeletionProductInCarton] = useState<{ id: string; quantity: number } | null>(null);
  const [selectedCarton, setSelectedCarton] = useState<Carton | null>(null);

  const [totalItems, setTotalItems] = useState(() => {
    const storedTotalItems = localStorage.getItem('totalItems');
    return storedTotalItems ? JSON.parse(storedTotalItems) : 0;
  });

  const [modalWeight, setModalWeight] = useState<string>('');
  const [showUnitCost, setShowUnitCost] = useState<boolean>(() => JSON.parse(localStorage.getItem('showUnitCost') || 'false'));
  const [poidsGlobalTotal, setPoidsGlobalTotal] = useState<number>(() => {
    const storedPoidsGlobalTotal = localStorage.getItem('poidsGlobalTotal');
    return storedPoidsGlobalTotal ? parseFloat(storedPoidsGlobalTotal) : 0;
  });

  const logContextState = () => {
    console.group('Current Context State');
    console.log('%cItems:', 'color: blue; font-weight: bold;', JSON.stringify(items, null, 2));
    console.log('%cHeaders:', 'color: blue; font-weight: bold;', JSON.stringify(headers, null, 2));
    console.log('%cShow Alert:', 'color: blue; font-weight: bold;', showAlert);
    console.log('%cAlert Message:', 'color: blue; font-weight: bold;', alertMessage);
    console.log('%cOrder By:', 'color: blue; font-weight: bold;', orderBy);
    console.log('%cSort Order:', 'color: blue; font-weight: bold;', order);
    console.log('%cProgress:', 'color: blue; font-weight: bold;', progress);
    console.log('%cBL Number:', 'color: blue; font-weight: bold;', blNumber);
    console.log('%cBL List:', 'color: blue; font-weight: bold;', JSON.stringify(blList, null, 2));
    console.log('%cError Message:', 'color: blue; font-weight: bold;', errorMessage);
    console.log('%cDelete Item:', 'color: blue; font-weight: bold;', JSON.stringify(deleteItem, null, 2));
    console.log('%cQuantity to Delete:', 'color: blue; font-weight: bold;', quantityToDelete);
    console.log('%cOpen Delete Modal:', 'color: blue; font-weight: bold;', openDeleteModal);
    console.log('%cWarehouse Headers:', 'color: blue; font-weight: bold;', JSON.stringify(warehouseHeaders, null, 2));
    console.log('%cOpen Modal:', 'color: blue; font-weight: bold;', openModal);
    console.log('%cCarton Lists:', 'color: blue; font-weight: bold;', JSON.stringify(cartonLists, null, 2));
    console.log('%cPalette Lists:', 'color: blue; font-weight: bold;', JSON.stringify(paletteLists, null, 2));
    console.log('%cCombined List:', 'color: blue; font-weight: bold;', JSON.stringify(combinedList, null, 2));
    console.log('%cReceived Data:', 'color: blue; font-weight: bold;', JSON.stringify(receivedData, null, 2));
    console.log('%cSorted Items:', 'color: blue; font-weight: bold;', JSON.stringify(sortedItems, null, 2));
    console.log('%cRemoved Items:', 'color: blue; font-weight: bold;', JSON.stringify(removedItems, null, 2));
    console.log('%cAll Content:', 'color: blue; font-weight: bold;', JSON.stringify(allContent, null, 2));
    console.log('%cOn Delete Product:', 'color: blue; font-weight: bold;', JSON.stringify(onDeleteProduct, null, 2));
    console.log('%cDeletion Product In Carton:', 'color: blue; font-weight: bold;', JSON.stringify(DeletionProductInCarton, null, 2));
    console.log('%cSelected Carton:', 'color: blue; font-weight: bold;', JSON.stringify(selectedCarton, null, 2));
    console.log('%cTotal Items:', 'color: blue; font-weight: bold;', totalItems);
    console.log('%cpoidsGlobalTotal:', 'color: blue; font-weight: bold;', poidsGlobalTotal);
    console.groupEnd();
  };

  useEffect(() => {
    localStorage.setItem('items', JSON.stringify(items));
    localStorage.setItem('headers', JSON.stringify(headers));
    localStorage.setItem('showAlert', JSON.stringify(showAlert));
    localStorage.setItem('alertMessage', alertMessage);
    localStorage.setItem('orderBy', orderBy);
    localStorage.setItem('order', order);
    localStorage.setItem('progress', progress.toString());
    localStorage.setItem('blNumber', blNumber);
    localStorage.setItem('blList', JSON.stringify(blList));
    localStorage.setItem('errorMessage', errorMessage);
    localStorage.setItem('deleteItem', JSON.stringify(deleteItem));
    localStorage.setItem('quantityToDelete', quantityToDelete.toString());
    localStorage.setItem('openDeleteModal', JSON.stringify(openDeleteModal));
    localStorage.setItem('warehouseHeaders', JSON.stringify(warehouseHeaders));
    localStorage.setItem('openModal', JSON.stringify(openModal));
    localStorage.setItem('cartonLists', JSON.stringify(cartonLists));
    localStorage.setItem('paletteLists', JSON.stringify(paletteLists));
    localStorage.setItem('combinedList', JSON.stringify(combinedList));
    localStorage.setItem('receivedData', JSON.stringify(receivedData));
    localStorage.setItem('sortedItems', JSON.stringify(sortedItems));
    localStorage.setItem('removedItems', JSON.stringify(removedItems));
    localStorage.setItem('allContent', JSON.stringify(allContent));
    localStorage.setItem('onDeleteProduct', JSON.stringify(onDeleteProduct));
    localStorage.setItem('DeletionProductInCarton', JSON.stringify(DeletionProductInCarton));
    localStorage.setItem('selectedCarton', JSON.stringify(selectedCarton));
    localStorage.setItem('totalItems', JSON.stringify(totalItems));
    localStorage.setItem('poidsGlobalTotal', JSON.stringify(poidsGlobalTotal));
    localStorage.setItem('modalWeight', modalWeight);
    localStorage.setItem('showUnitCost', JSON.stringify(showUnitCost));
  }, [items, headers, showAlert, alertMessage, orderBy, order, progress, blNumber, blList, errorMessage, deleteItem, quantityToDelete, openDeleteModal, warehouseHeaders, openModal, cartonLists, paletteLists, combinedList, receivedData, sortedItems, removedItems, allContent, onDeleteProduct, DeletionProductInCarton, selectedCarton, totalItems, modalWeight, showUnitCost,poidsGlobalTotal]);

  
  const handleReset = (closeModal: () => void) => {
    logContextState();
    setItems([]);
    setHeaders([]);
    setShowAlert(false);
    setAlertMessage('');
    setOrderBy('RefTILOLI');
    setOrder('asc');
    setProgress(0);
    setBlNumber('');
    setBlList([]);
    setErrorMessage('');
    setDeleteItem(null);
    setQuantityToDelete(1);
    setOpenDeleteModal(false);
    setWarehouseHeaders([]);
    setOpenModal(false);
    setCartonLists([]);
    setPaletteLists([]);
    setCombinedList([]);
    setReceivedData(null);
    setSortedItems([]);
    setRemovedItems([]);
    setAllContent({ cartonLists: [], paletteLists: [] });
    setOnDeleteProduct(null);
    setSelectedCarton(null);
    setTotalItems(0);
    setPoidsGlobalTotal(0);

    const keys = [
      'items', 'headers', 'showAlert', 'alertMessage', 'orderBy', 'order',
      'progress', 'blNumber', 'blList', 'errorMessage', 'deleteItem',
      'quantityToDelete', 'openDeleteModal', 'warehouseHeaders', 'openModal',
      'cartonLists', 'paletteLists', 'combinedList', 'receivedData', 'sortedItems',
      'removedItems', 'allContent', 'onDeleteProduct', 'totalItems', 'PoidsGlobalTotal'
    ];
    keys.forEach(key => localStorage.removeItem(key));
    
    closeModal();

  };

  const handleDataReceived = (data: any) => {
    setReceivedData(data);
  };

  return (
    <AppContext.Provider
      value={{
        items,
        setItems,
        headers,
        setHeaders,
        showAlert,
        setShowAlert,
        alertMessage,
        setAlertMessage,
        orderBy,
        setOrderBy,
        order,
        setOrder,
        progress,
        setProgress,
        blNumber,
        setBlNumber,
        blList,
        setBlList,
        errorMessage,
        setErrorMessage,
        deleteItem,
        setDeleteItem,
        quantityToDelete,
        setQuantityToDelete,
        openDeleteModal,
        setOpenDeleteModal,
        warehouseHeaders,
        setWarehouseHeaders,
        openModal,
        setOpenModal,
        cartonLists,
        setCartonLists,
        paletteLists,
        setPaletteLists,
        combinedList,
        setCombinedList,
        receivedData,
        setReceivedData,
        sortedItems,
        setSortedItems,
        removedItems,
        setRemovedItems,
        allContent,
        setAllContent,
        onDeleteProduct,
        setOnDeleteProduct,
        handleReset,
        handleDataReceived,
        DeletionProductInCarton,
        setDeletionProductInCarton,
        selectedCarton, 
        setSelectedCarton,
        totalItems,
        setTotalItems,
        modalWeight,
        setModalWeight,
        showUnitCost, 
        setShowUnitCost,
        logContextState,
        poidsGlobalTotal,
        setPoidsGlobalTotal,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
