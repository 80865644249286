import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Modal,
  Box,
  Typography,
  Switch
} from '@mui/material';
import { useAppContext } from './AppContext';
import fetchFunction from './fetchFunction';
import './App.css';
// import { exportLogs } from './logService';
import resetScanImage from './imageScan/resetscan.png';
import frenchImage from './imageScan/french.png';
import noneImage from './imageScan/Blank.png';
import addSuffixImage from './imageScan/AddSuffix.png';
import specialCaracterImage from './imageScan/specialCaracter.png';
import exitSuffixImage from './imageScan/exitsuffix.png';
import disable2D from './imageScan/Disable2D.png';
import enable1D from './imageScan/Enable 1D.png';
import disable1D from './imageScan/barcode1Doff.png';
import enable2D from './imageScan/barcode2DOn.png';
interface LogEntry {
  level: string;
  message: string;
  timestamp: string;
}
const BLSection: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const {
    warehouseHeaders,
    setWarehouseHeaders,
    setItems,
    blList,
    setBlList,
    cartonLists,
    setCartonLists,
    setSelectedCarton,
    blNumber, 
    setBlNumber,
    showUnitCost,
    setShowUnitCost,
    items
  } = useAppContext();

  const [openScannerConfig, setOpenScannerConfig] = useState(false);

const handleOpenScannerConfig = () => {
  setOpenScannerConfig(true);
};

const handleCloseScannerConfig = () => {
  setOpenScannerConfig(false);
};

const [openResetConfig, setOpenResetConfig] = useState(false);

const handleOpenResetConfig = () => {
  setOpenResetConfig(true);
};

const handleCloseResetConfig = () => {
  setOpenResetConfig(false);
};

  const handleBlListChange = (newBlList: string[]) => {
    setBlList(newBlList);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setBlNumber('');
    setLocalErrorMessage('');
    setIsSubmitting(false); 
  };

  const handleBlSubmit = () => {
    if (isSubmitting) {
      return; 
    }
    function formatCodeEAN(codeEAN: string): string {
      const maxLength = 13;
      const currentLength = codeEAN.length;
      
      if (currentLength < maxLength) {
          const numberOfZerosToAdd = maxLength - currentLength;
          const zeros = '0'.repeat(numberOfZerosToAdd);
          console.log('[BlSection.tsx] Adding zeros to codeEAN. Result:', zeros + codeEAN);
          return zeros + codeEAN;
      }
      console.log('[BlSection.tsx] CodeEAN does not need formatting.');
      return codeEAN;
  }

    setIsSubmitting(true); 

    if (blList.includes(blNumber)) {
      console.log('[BlSection.tsx] BP number already exists in the list.');
      setLocalErrorMessage("Ce numéro BP existe déjà dans la liste.");
      setIsSubmitting(false); 
      return;
    }
    if (blNumber.trim() === '') {
      console.log('[BlSection.tsx] Empty BP number detected.');
      setLocalErrorMessage('Le numéro BP ne peut pas être vide.');
      setIsSubmitting(false); 
      return;
    }

    fetchFunction({
      noBl: blNumber,
      warehouseHeaders,
      setWarehouseHeaders,
      setItems, 
      items
    })
      .then((blFound) => {
        if (blFound) {
          const firstBlProducts = warehouseHeaders.length > 0 ? warehouseHeaders[0].products : [];
          const firstBlDestinationNo = firstBlProducts.length > 0 ? firstBlProducts[0].Destination_No : null;

          const newBlProducts = blFound.products || [];
          const newBlDestinationNo = newBlProducts.length > 0 ? newBlProducts[0].Destination_No : null;

          if (firstBlDestinationNo && newBlDestinationNo && newBlDestinationNo !== firstBlDestinationNo) {
            setLocalErrorMessage("Le client de ce BP ne correspond pas à celui du premier BP.");
            setIsSubmitting(false); 
            return;
          }

          setBlList(prevList => {
            const newList = [...prevList, blNumber];
            console.log('[BlSection.tsx] BP number added to the list:', blNumber);
            handleBlListChange(newList);
            return newList;
          });

          setWarehouseHeaders(prevHeaders => [...prevHeaders, blFound]);

          setItems(prevItems => [
            ...prevItems,
            ...newBlProducts.map(product => ({
              ...product,
              id: product.id,
              Quantity: product.Qty_to_Ship ,
              ActualQuantity: product.Quantity,
              PreviousActualQuantity: product.Quantity,
              Destination_No: product.Destination_No,
              Bin_Code: product.Bin_Code,
              externalDocNo: product.externalDocNo,
              Code_EAN: formatCodeEAN(product.Code_EAN)
            }))
          ]);


          if (blList.length === 0) {
            const newCarton = { name: "Carton #1", items: [], totalPoidsCarton: 0 };
            const newCartonLists = [...cartonLists, newCarton];
            setCartonLists(newCartonLists);
            setSelectedCarton(newCarton);
          }

          handleCloseModal();
        } else {
          setLocalErrorMessage('BP introuvable.');
        }
      })
      .catch((error) => {
        console.error('Error handling BP submit:', error);
        setLocalErrorMessage('Erreur lors de la soumission du BP.');
      })
      .finally(() => {
        setIsSubmitting(false); 
      });
  };

  const handleSnackbarClose = () => {
    setLocalErrorMessage('');
  };

  const [, setLogs] = useState<LogEntry[]>([]);

  useEffect(() => {
      const savedLogs = JSON.parse(localStorage.getItem('logs') || '[]') as LogEntry[];
      setLogs(savedLogs);
  }, []);


  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowUnitCost(event.target.checked);
  };

  return (
    <div className="bl-section">
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
        <Button variant="contained" onClick={handleOpenModal}>+ BP</Button>
      </div>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Ajouter un BP</DialogTitle>
        <DialogContent>
          <TextField
            label="Numéro BP"
            value={blNumber}
            onChange={(e) => setBlNumber(e.target.value)}
            fullWidth
          />
          {localErrorMessage && <p style={{ color: 'red' }}>{localErrorMessage}</p>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Annuler</Button>
          <Button onClick={handleBlSubmit} disabled={isSubmitting}>Valider</Button>
        </DialogActions>
      </Dialog>

      <div>
        {blList.map((bl, index) => (
          <div key={index}>{bl}</div>
        ))}
      </div>

      <Snackbar
        open={Boolean(localErrorMessage)}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={localErrorMessage}
      />

<Modal
  open={openScannerConfig}
  onClose={handleCloseScannerConfig}
  aria-labelledby="scanner-config-modal-title"
  aria-describedby="scanner-config-modal-description"
>
  <Box 
    sx={{ 
      position: 'absolute', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      width: 600, 
      height: '80vh', // Adjust height as needed
      bgcolor: 'background.paper', 
      border: '2px solid #000', 
      boxShadow: 24, 
      p: 4,
      overflowY: 'scroll' // Enable vertical scrolling
    }}
  >
    <Box textAlign="center">
    <Typography id="scanner-config-modal-title" variant="h3" component="h2" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#0b2356' }}>
      Configurer le Scanner
    </Typography>
    </Box>
    <Box id="scanner-config-modal-description" sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            1. Reset Scan
          </Typography>
          <img src={resetScanImage} alt="Reset Scan" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            2. Mettre en Français
          </Typography>
          <img src={frenchImage} alt="French" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            3. Mettre None à la place de Enter
          </Typography>
          <img src={noneImage} alt="None" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            4. Création d'un suffixe
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'blue' }}>
              4.1 Ajout suffixe
            </Typography>
            <img src={addSuffixImage} alt="Add Suffix" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'blue' }}>
              4.2 Ajout caractere special
            </Typography>
            <img src={specialCaracterImage} alt="Special Caracter" style={{ width: '50%', display: 'block', margin: '0 auto' }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'blue' }}>
              4.3 Fin de l'ajout du suffixe
            </Typography>
            <img src={exitSuffixImage} alt="Exit Suffix" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
          </Box>
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            5. Type BarCode
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'blue' }}>
              5.1 Désactiver code 2D
            </Typography>
            <img src={disable2D} alt="Add Suffix" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1rem', color: 'blue' }}>
              5.2 Activer code 1D
            </Typography>
            <img src={enable1D} alt="Add Suffix" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
          </Box>
          </Box>
      </Box>
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Typography component="a" href="https://www.dropbox.com/scl/fo/pjozmqqjc7bq0plmtn48i/ACdhOZNjByEuM9ydMt8h_04?e=4&preview=TR-UM006-EN+Ver.01.1.01.pdf&rlkey=ddymst991sabi417jz14azmg2&dl=0" target="_blank" rel="noopener noreferrer" sx={{ color: '#e58508', textDecoration: 'none', fontWeight: 'bold' }}>
          Cliquez ici pour plus de détails
        </Typography>
      </Box>
    </Box>
  </Box>
</Modal>

<Modal
    open={openResetConfig}
    onClose={handleCloseResetConfig}
    aria-labelledby="reset-config-modal-title"
    aria-describedby="reset-config-modal-description"
  >
  <Box 
    sx={{ 
      position: 'absolute', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)', 
      width: 600, 
      height: '80vh', // Adjust height as needed
      bgcolor: 'background.paper', 
      border: '2px solid #000', 
      boxShadow: 24, 
      p: 4,
      overflowY: 'scroll' // Enable vertical scrolling
    }}
  >
    <Box textAlign="center">
    <Typography id="reset-config-modal-title" variant="h3" component="h2" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#0b2356' }}>
      Reset scanner
    </Typography>
    </Box>
    <Box id="reset-config-modal-description" sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            1. Supprimer barcode 1D
          </Typography>
          <img src={disable1D} alt="Reset Scan" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            2. Ajouter barcode 2D
          </Typography>
          <img src={enable2D} alt="French" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        <Box textAlign="center">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: 'blue' }}>
            3. Remise à zéro
          </Typography>
          <img src={resetScanImage} alt="None" style={{ width: '40%', display: 'block', margin: '0 auto' }} />
        </Box>
        </Box>
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Typography component="a" href="https://www.dropbox.com/scl/fo/pjozmqqjc7bq0plmtn48i/ACdhOZNjByEuM9ydMt8h_04?e=4&preview=TR-UM006-EN+Ver.01.1.01.pdf&rlkey=ddymst991sabi417jz14azmg2&dl=0" target="_blank" rel="noopener noreferrer" sx={{ color: '#e58508', textDecoration: 'none', fontWeight: 'bold' }}>
          Cliquez ici pour plus de détails
        </Typography>
      </Box>
    </Box>
  </Box>
</Modal>

<div style={{ marginTop: 'auto', marginBottom: '10px', textAlign: 'center' }}>
      <Button variant="contained" onClick={handleOpenResetConfig}>Reset Scanner</Button>
      <Button variant="contained" onClick={handleOpenScannerConfig}>Config Scanner</Button>
      {/* <Button onClick={exportLogs}>Exporter Logs</Button> */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Switch
          {...label}
          checked={showUnitCost}
          onChange={handleSwitchChange}
          color="primary"
        />
        <Typography variant="subtitle1">Afficher le poids unitaire (PDF)</Typography>
      </Box>
      <Typography variant="body2" color="textSecondary">V1.3.2</Typography>
      </div>
    </div>
  );
};

export default BLSection;
