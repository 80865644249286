import React from 'react';
import { useAppContext } from './AppContext';
import { Product } from './interfaces';
import { useAuth } from './Context/AuthContext';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

type Carton = {
  name: string;
  items: Product[];
  totalPoidsCarton?: number;
};

type Palette = {
  name: string;
  items: (Product | Carton)[];
};

type AllContent = {
  cartonLists: Carton[];
  paletteLists: Palette[];
};

type ExcelContent = {
  totalProducts: number;
  totalQuantity: number;
  totalWeight: string;
  headerData: { 
    destinationNo: string;
    formattedDateToDisplay: string;
    formattedDate: string;
    operatorName: string;
  };
  items: {
    'Num PO': string | undefined;
    'Référence': string;
    'Code EAN': string;
    'Description': string;
    'Quantité': number;
    'Poids unitaire brut': number;
  }[];
};

const getBase64FromUrl = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const generateExcel = async ({
  filename,
  content,
  logoSrc,
  cartonName
}: {
  filename: string;
  content: ExcelContent;
  logoSrc: string;
  cartonName: string;
}): Promise<void> => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.getCell('A1').font = { bold: true, size: 24 };
  worksheet.mergeCells('A1:F1');
  const titleCell = worksheet.getCell('A1');
  titleCell.value = `Packing list : ${content.headerData.destinationNo}_${content.headerData.formattedDate}_${cartonName}`;
  titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
  titleCell.border = {
    top: { style: 'thick' },
    left: { style: 'thick' },
    bottom: { style: 'thick' },
    right: { style: 'thick' },
  };

  const imageId = workbook.addImage({
    base64: logoSrc,
    extension: 'png',
  });
  worksheet.addImage(imageId, 'F3:F5');

  const operatorInitials = content.headerData.operatorName
    .split(' ')
    .map((n) => n[0])
    .join('.');

  const headers = [
    { label: 'Date', value: content.headerData.formattedDateToDisplay },
    { label: 'Numéro Client', value: content.headerData.destinationNo },
    { label: 'Opérateur', value: operatorInitials },
  ];

  headers.forEach((header, index) => {
    const labelCell = worksheet.getCell(`${String.fromCharCode(65 + index * 2)}2`);
    const valueCell = worksheet.getCell(`${String.fromCharCode(66 + index * 2)}2`);
    
    labelCell.value = header.label;
    valueCell.value = header.value;

    labelCell.font = { bold: true, size: 16 };
    valueCell.font = { size: 16 };

    labelCell.alignment = { horizontal: 'center', vertical: 'middle' };
    valueCell.alignment = { horizontal: 'center', vertical: 'middle' };

    labelCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };

    valueCell.border = {
      top: { style: 'thin' },
      bottom: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
    };
  });

  const addBorderedBox = (startCell: string, data: string[][]) => {
    const startRow = parseInt(startCell.replace(/[^0-9]/g, ''), 10);
    data.forEach((row, rowIndex) => {
      row.forEach((value, colIndex) => {
        const cellAddress = `${String.fromCharCode(65 + colIndex)}${startRow + rowIndex}`;
        const cell = worksheet.getCell(cellAddress);
        cell.value = value;
        cell.font = { bold: true, size: 16 };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
  };

  // Adding a bordered box for totals
  addBorderedBox('C3', [
    ['Total Produits', content.totalProducts.toString()],
    ['Quantité Totale', content.totalQuantity.toString()],
    ['Poids Total', content.totalWeight],
  ]);

  const dataHeaders = ['Num PO', 'Référence', 'Code EAN', 'Description', 'Quantité', 'Poids unitaire brut'];
  const colors = ['FFC0CB', 'FFDAB9'];

  dataHeaders.forEach((header, index) => {
    const cell = worksheet.getCell(`${String.fromCharCode(65 + index)}7`); // Headers start at row 7
    cell.value = header;
    cell.font = { bold: true, size: 16 };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: index % 2 === 0 ? colors[0] : colors[1] },
    };
  });

  content.items.forEach((item, rowIndex) => {
    dataHeaders.forEach((header, colIndex) => {
      const cell = worksheet.getCell(`${String.fromCharCode(65 + colIndex)}${8 + rowIndex}`);
      cell.font = { size: 16 };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.value = item[header as keyof typeof item];
    });
  });

  if (worksheet.columns) {
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      if (column && column.eachCell) {
        column.eachCell({ includeEmpty: true }, (cell) => {
          const cellValue = cell.value != null ? cell.value.toString() : '';
          maxLength = Math.max(maxLength, cellValue.length + 2);
        });
        column.width = maxLength;
      }
    });
  }

  worksheet.pageSetup = {
    printArea: 'A1:F30',
    orientation: 'landscape',
    fitToPage: true,
  };


  worksheet.views = [
    {
      showGridLines: false,
    },
  ];


  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(blob, filename);
};

export const handlePrintCartonUnderPalette = async (
  allContent: AllContent,
  paletteName: string,
  cartonIndex: number,
  items: Product[],
  user: { displayName?: string } | null
): Promise<void> => {
  const palette = allContent.paletteLists.find((palette) => palette.name === paletteName);

  if (!palette) {
    console.error(`Palette "${paletteName}" not found.`);
    return;
  }

  const carton = palette.items[cartonIndex] as Carton;

  if (!carton || !('items' in carton)) {
    console.error(`Carton at index ${cartonIndex} not found or is invalid under palette "${paletteName}".`);
    return;
  }

  const totalWeight = carton.totalPoidsCarton !== undefined ? carton.totalPoidsCarton + ' kg' : 'N/A';
  const totalQuantity = carton.items.reduce((sum, item) => sum + item.Quantity, 0);
  const uniqueEanCodes = new Set(carton.items.map((item) => item.Code_EAN));
  const totalProducts = uniqueEanCodes.size;
  const destinationNo = items.length > 0 ? items[0].Destination_No ?? '' : '';

  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}${month}${year}${hours}${minutes}`;
  };

  const date = new Date();
  const formattedDate = formatDate(date);

  const formatDateDisplay = (date: Date): string => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const formattedDateToDisplay = formatDateDisplay(date);

  const excelContent: ExcelContent = {
    totalProducts,
    totalQuantity,
    totalWeight,
    headerData: {
      destinationNo,
      formattedDate,
      formattedDateToDisplay,
      operatorName: user?.displayName ?? 'Non renseigné',
    },
    items: carton.items.map((item) => ({
      'Num PO': item.externalDocNo,
      'Référence': item.RefTILOLI,
      'Code EAN': item.Code_EAN,
      'Description': item.Description,
      'Quantité': item.Quantity,
      'Poids unitaire brut': item.ItemGrossWeight,
    })),
  };

  const filename = `${destinationNo}_${formattedDate}_PackingList_${carton.name}.xlsx`;
  const logoSrc = await getBase64FromUrl('/LogoTiloliClassic.png');

  await generateExcel({
    filename,
    content: excelContent,
    logoSrc,
    cartonName: carton.name,
  });
};


const PalettePrintButton: React.FC<{ paletteName: string; cartonIndex: number }> = ({ paletteName, cartonIndex }) => {
  const { allContent, items } = useAppContext();
  const { user } = useAuth();
  const handlePrint = () => {
    
    handlePrintCartonUnderPalette(allContent, paletteName, cartonIndex,items, user);
  };

  return (
    <button onClick={handlePrint}>Print Carton at Index {cartonIndex} under Palette: {paletteName}</button>
  );
};

export default PalettePrintButton;
