import React, { useState, useEffect, useRef, useCallback } from 'react';
// import { handleGlobalPrint } from './PrintPage';
import { handleCartonPrint } from './PrintCarton';
import { handlePrintPalette } from './PrintPalette';
import {handlePrintCartonUnderPalette} from './PrintCartonUnderPalette';
import {
  Button,
  Typography,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Alert,
  List, ListItem,  ListItemText, Collapse,
  Modal,
} from '@mui/material';

import { useAuth } from './Context/AuthContext';
import PrintIcon from '@mui/icons-material/Print';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ScaleIcon from '@mui/icons-material/Scale';
import { Product } from './interfaces';
import './App.css';
import { useAppContext } from './AppContext';
import ErrorModal from './errorModal';
import * as Tone from 'tone';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#E4007C',
    },
  },
});

type Carton = { name: string, items: Product[], totalPoidsCarton?: number; };
// type Palette = { name: string, items: (Product | Carton)[] };

const playErrorSound = () => {
  const synth = new Tone.Synth().toDestination();
  synth.triggerAttackRelease("C4", "8n");
};

const playWarningSound = () => {
  const synth = new Tone.Synth().toDestination();
  synth.triggerAttackRelease("G4", "8n");
};

const CartonPalette: React.FC = () => {
  const { items, setItems, receivedData,setReceivedData, cartonLists, setCartonLists, paletteLists, setPaletteLists, setCombinedList, setAllContent, allContent, selectedCarton, setSelectedCarton, totalItems, setTotalItems, modalWeight, setModalWeight, poidsGlobalTotal, setPoidsGlobalTotal } = useAppContext();
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedList, setSelectedList] = useState<{ name: string, items: (Product | { name: string, items: Product[] })[] } | null>(null);
  const [ean, setEan] = useState('');
  const [quantity, setQuantity] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const [addedProducts, setAddedProducts] = useState<Product[]>([]);
  const [open, setOpen] = useState(Array(paletteLists.length).fill(false));
  const [productListAdded, setProductListAdded] = useState<boolean[]>(Array(paletteLists.length).fill(false));

  const generateCombinedList = (cartonLists: { name: string, items: Product[] }[], paletteLists: { name: string, items: (Product | { name: string, items: Product[] })[] }[]): string[] => {
    const list: string[] = [
      ...cartonLists.map(carton => carton.name),
      ...paletteLists.flatMap(palette => {
        const cartonNames: string[] = [];
        palette.items.forEach(carton => {
          if ('name' in carton && typeof carton.name === 'string' && carton.name.startsWith('Carton #')) {
            cartonNames.push(carton.name);
          }
        });
        return [...cartonNames, palette.name];
      })
    ];

    return list;
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>('');
  const { user } = useAuth();
  const [openCartonModal, setOpenCartonModal] = useState(false);
  const [openPaletteModal, setOpenPaletteModal] = useState(false);
  const [currentCartonName, setCurrentCartonName] = useState<string | null>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<Product | null>(null);
  const [cartonSelectedToDelete, setCartonSelectedToDelete] = useState<Carton | undefined>();
  const [showClearCartonModal, setShowClearCartonModal] = useState(false);
  const [cartonToClear, setCartonToClear] = useState<Carton | null>(null);
  const [showClearPaletteModal, setShowClearPaletteModal] = useState(false);
  const [paletteNameToClear, setPaletteNameToClear] = useState<string>('');
  const [cartonIndexToClear, setCartonIndexToClear] = useState<number>(-1);
  const [paletteIndexToClear, setPaletteIndexToClear] = useState<number>(-1);
  const [showClearCartonInPaletteModal, setShowClearCartonInPaletteModal] = useState(false);

  const openClearCartonInPaletteModal = (paletteIndex: number, cartonIndex: number) => {
    setPaletteIndexToClear(paletteIndex);
    setCartonIndexToClear(cartonIndex);
    setShowClearCartonInPaletteModal(true);
}

  const openClearCartonModal = (carton: Carton) => {
      setCartonToClear(carton);
      setShowClearCartonModal(true);
  };

  const openConfirmationModal = (item: Product, carton: Carton) => {
    setItemToDelete(item);
    setCartonSelectedToDelete(carton);
    setShowConfirmationModal(true);
  };

  const openClearPaletteModal = (paletteName: string) => {
    setPaletteNameToClear(paletteName);  
    setShowClearPaletteModal(true);  
}

  

  useEffect(() => {
    const updatedCombinedList = generateCombinedList(cartonLists, paletteLists);
    setCombinedList(updatedCombinedList);
  }, [cartonLists, paletteLists, setCombinedList]);

  useEffect(() => {
    setAllContent({ cartonLists, paletteLists });
  }, [cartonLists, paletteLists, setAllContent]);

  useEffect(() => {
    const storedProducts = localStorage.getItem('addedProducts');
    if (storedProducts) {
      setAddedProducts(JSON.parse(storedProducts));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('addedProducts', JSON.stringify(addedProducts));
  }, [addedProducts]);

  const handleCloseProductModal = () => {
    setOpenProductModal(false);
    setSelectedList(null);
    setEan('');
    setQuantity(1);
    setError('');
  };

  const handleDelete = (item: Product, cartonSelected: Carton) => {
    openConfirmationModal(item,cartonSelected);

    console.log('[CartonPalette.tsx] function handleDelete item:', item);
    console.log('[CartonPalette.tsx] function handleDelete cartonSelected:', cartonSelected);
    console.log('[CartonPalette.tsx] function handleDelete items before update:', items);
    console.log('[CartonPalette.tsx] function handleDelete cartonLists before update:', cartonLists);
    console.log('[CartonPalette.tsx] function handleDelete paletteLists before update:', paletteLists);
    const updatedItems = items.map((product) => {
      console.log('[CartonPalette.tsx] function handleDelete Mapping items - product:', product);
      if (product.id === item.id) {
        console.log('[CartonPalette.tsx] function handleDelete Match found for item id:', item.id);
        return {
          ...product,
          ActualQuantity: product.ActualQuantity + item.Quantity,
        };
      }
      return product;
    });

    const updatedCartonLists = cartonLists.map((carton) => {
      console.log('[CartonPalette.tsx] function handleDelete Mapping cartonLists - carton:', carton);
      if (carton.name === cartonSelected.name) {
        console.log('[CartonPalette.tsx] function handleDelete Match found for carton name:', cartonSelected.name);
        return {
          ...carton,
          items: carton.items.filter((cartonItem) => cartonItem.id !== item.id),
        };
      }
      return carton;
    });

    const updatedPaletteLists = paletteLists.map((palette) => ({
      ...palette,
      items: palette.items.map((cartonOrProduct) => {
        if ('items' in cartonOrProduct && cartonOrProduct.name === cartonSelected.name) {
          return {
            ...cartonOrProduct,
            items: cartonOrProduct.items.filter(
              (cartonItem) => cartonItem.id !== item.id
            ),
          };
        }
        return cartonOrProduct;
      }),
    }));

    setItems(updatedItems);
    setCartonLists(updatedCartonLists);
    setPaletteLists(updatedPaletteLists);
    setShowConfirmationModal(false);
    console.log('[CartonPalette.tsx] function handleDelete updatedItems:', updatedItems);
    console.log('[CartonPalette.tsx] function handleDelete updatedCartonLists:', updatedCartonLists);
    console.log('[CartonPalette.tsx] function handleDelete updatedPaletteLists:', updatedPaletteLists);
  };

  const handleAddCarton = () => {
    console.log('[cartonPalette.tsx] handleAddCarton - cartonLists before adding new carton:', cartonLists);
    const newCarton = { name: getNextCartonName(), items: [], totalPoidsCarton: 0 };
    console.log('[cartonPalette.tsx] handleAddCarton - newCarton:', newCarton);
    const newCartonLists = [...cartonLists, newCarton];
    console.log('[cartonPalette.tsx] handleAddCarton - newCartonLists:', newCartonLists);
    setCartonLists(newCartonLists);
    setSelectedCarton(newCarton);
  };

  const handleAddPalette = () => {
    console.log('[cartonPalette.tsx] handleAddPalette - paletteLists before adding new palette:', paletteLists);
    const newPaletteList = [...paletteLists, { name: `Palette #${paletteLists.length + 1}`, items: [] }];
    console.log('[cartonPalette.tsx] handleAddPalette - newPaletteList:', newPaletteList);
    setPaletteLists(newPaletteList);
  };
  
  const handleAddCartonUnderPalette = (paletteIndex: number) => {
  
    const newCarton = { name: getNextCartonName(), items: [], totalPoidsCarton: 0 };
    console.log('[cartonPalette.tsx] handleAddCartonUnderPalette - paletteLists before adding new carton under palette at index', paletteIndex);
    console.log('[cartonPalette.tsx] handleAddCartonUnderPalette - newCarton:', newCarton);
    const updatedPaletteLists = [...paletteLists];
    updatedPaletteLists[paletteIndex].items.push(newCarton);
    console.log('[cartonPalette.tsx] handleAddCartonUnderPalette - updatedPaletteLists:', updatedPaletteLists);
    setSelectedCarton(newCarton);
    setPaletteLists(updatedPaletteLists);
    console.log('[cartonPalette.tsx] handleAddCartonUnderPalette - selected carton:', newCarton);
    console.log('[cartonPalette.tsx] handleAddCartonUnderPalette - final paletteLists:', updatedPaletteLists);

    if (focusElementRef.current) {
      focusElementRef.current.focus();
    }
  };

  const focusElementRef = useRef<HTMLDivElement>(null);
  const [, setFocusedElement] = useState<{ paletteIndex: number | null, cartonIndex: number | null }>({
    paletteIndex: null,
    cartonIndex: null,
  });
  useEffect(() => {
    if (focusElementRef.current) {
      focusElementRef.current.focus();
    }
  }, [cartonLists]);
  
  
  const handleAddProductUnderPalette = useCallback(
    (paletteIndex: number) => {
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - Adding product under palette at index ${paletteIndex}`);
      const paletteName = paletteLists[paletteIndex].name;
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - Palette name: ${paletteName}`);
      const newCartonName = `Produits sans carton (${paletteName})`;
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - newCartonName: ${newCartonName}`);
      const newCarton = { name: newCartonName, items: [] };
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - newCarton:`, newCarton);
      const updatedPaletteLists = [...paletteLists];
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - updatedPaletteLists:`, updatedPaletteLists);
      updatedPaletteLists[paletteIndex].items.push(newCarton);
      const updatedProductListAdded = [...productListAdded];
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - updatedProductListAdded:`, updatedProductListAdded);
      updatedProductListAdded[paletteIndex] = true;
      setProductListAdded(updatedProductListAdded);
      console.log(`[cartonPalette.tsx] handleAddProductUnderPalette - selected carton:`, newCarton);
      setSelectedCarton(newCarton);
      // setPaletteLists(updatedPaletteLists); 
    },
    [paletteLists, productListAdded,setProductListAdded, setSelectedCarton]
  );
  
  
  const handleOpenProductModal = (carton: Product | { name: string, items: Product[] }) => {
    if ('name' in carton) {
      setSelectedList(carton); 
      setOpenProductModal(true);
    }
  };

  const handleAddProduct = () => {
    console.log('[cartonPalette.tsx] handleAddProduct - selectedList:', selectedList);
console.log('[cartonPalette.tsx] handleAddProduct - ean:', ean);
console.log('[cartonPalette.tsx] handleAddProduct - quantity:', quantity);
    if (selectedList && selectedList.items && ean.trim() !== '' && quantity > 0) {
        const item = items.find(item => item.Code_EAN === ean);
        console.log('[cartonPalette.tsx] handleAddProduct - item found:', item);
        if (!item) {
          setErrorModalMessage("Produit non trouvé dans la liste des articles.");
          console.log('[cartonPalette.tsx] handleAddProduct - Error: Produit non trouvé dans la liste des articles.');
          playErrorSound();
          setModalOpen(true);            
          return;
        }
        // if (quantity > item.ActualQuantity) {
        //   setErrorModalMessage("La quantité disponible est insuffisante.");
        //   playErrorSound();
        //   setModalOpen(true);
        //   return;
        // }

        // const updatedItems = items.map(product => {
        //     if (product.Code_EAN === ean) {
        //         return { ...product, ActualQuantity: product.ActualQuantity - quantity };
        //     }
        //     return product;
        // });
        // setItems(updatedItems);

        const updatedCartons = cartonLists.map(carton => {
            if (carton.name === selectedList.name) {
                const existingItem = carton.items.find(cartonItem => cartonItem.id === item.id);
                if (existingItem) {
                  console.log('[cartonPalette.tsx] handleAddProduct - Existing item found in carton:', existingItem);
                    return {
                        ...carton,
                        items: carton.items.map(cartonItem =>
                            cartonItem.id === item.id
                                ? { ...cartonItem, Quantity: cartonItem.Quantity + quantity }
                                : cartonItem
                        ),
                    };
                } else {
                  console.log('[cartonPalette.tsx] handleAddProduct - New item will be added to carton:', item);
                    const newItem = {
                        ...item,
                        Quantity: quantity
                    };
                    return { ...carton, items: [...carton.items, newItem] };
                }
            }
            return carton;
        });

        const updatedPalettes = paletteLists.map(palette => {
            const updatedCartonsInPalette = palette.items.map(cartonOrProduct => {
                if ('items' in cartonOrProduct && cartonOrProduct.name === selectedList.name) {
                    const existingItem = cartonOrProduct.items.find(cartonItem => cartonItem.id === item.id);
                    if (existingItem) {
                      console.log('[cartonPalette.tsx] handleAddProduct - Existing item found in palette:', existingItem);
                        return {
                            ...cartonOrProduct,
                            items: cartonOrProduct.items.map(cartonItem =>
                                cartonItem.id === item.id
                                    ? { ...cartonItem, Quantity: cartonItem.Quantity + quantity }
                                    : cartonItem
                            ),
                        };
                    } else {
                      console.log('[cartonPalette.tsx] handleAddProduct - New item will be added to palette:', item);
                        const newItem = {
                            ...item,
                            Quantity: quantity
                        };
                        return { ...cartonOrProduct, items: [...cartonOrProduct.items, newItem] };
                    }
                }
                return cartonOrProduct;
            });
            return { ...palette, items: updatedCartonsInPalette };
        });

        setCartonLists(updatedCartons);
        setPaletteLists(updatedPalettes);

        handleCloseProductModal();
    } else {
      setErrorModalMessage("Veuillez saisir un code EAN valide et une quantité supérieure à zéro.");
      setModalOpen(true);  
        setError('Veuillez saisir un code EAN valide et une quantité supérieure à zéro.');
        playWarningSound();
        console.log('[cartonPalette.tsx] handleAddProduct - Error: Veuillez saisir un code EAN valide et une quantité supérieure à zéro.');
    }
};

  

const handleClearCarton = (carton: Carton) => {
  console.log('[cartonPalette.tsx] handleClearCarton - Carton to clear:', carton);

  const updatedCartonLists = cartonLists.filter((c) => c.name !== carton.name);
  console.log('[cartonPalette.tsx] handleClearCarton - Updated carton lists:', updatedCartonLists);

  const updatedItems = items.map((product) => {
      const itemInCarton = carton.items.find((item) => item.id === product.id);
      if (itemInCarton) {
          console.log('[cartonPalette.tsx] handleClearCarton - Restoring quantity for item:', product);
          return {
              ...product,
              ActualQuantity: product.ActualQuantity + itemInCarton.Quantity
          };
      }
      return product;
  });

  setItems(updatedItems);
  setCartonLists(updatedCartonLists);
};

const handleClearPalette = (paletteName: string) => {
  console.log('[cartonPalette.tsx] handleClearPalette - Palette name to clear:', paletteName);

  const updatedPaletteLists = [...paletteLists];
  const paletteIndex = updatedPaletteLists.findIndex(palette => palette.name === paletteName);
  console.log('[cartonPalette.tsx] handleClearPalette - Found palette at index:', paletteIndex);

  if (paletteIndex !== -1) {
      updatedPaletteLists.splice(paletteIndex, 1);
      console.log('[cartonPalette.tsx] handleClearPalette - Updated palette lists:', updatedPaletteLists);

      const updatedItems = items.map(product => {
          const itemsInRemovedPalette = paletteLists[paletteIndex].items
              .filter(item => 'items' in item)
              .flatMap(item => (item as { name: string, items: Product[] }).items);

          const itemInPalette = itemsInRemovedPalette.find(item => item.id === product.id);
          if (itemInPalette) {
              console.log('[cartonPalette.tsx] handleClearPalette - Restoring quantity for item:', product);
              return {
                  ...product,
                  ActualQuantity: product.ActualQuantity + itemInPalette.Quantity
              };
          }
          return product;
      });

      setItems(updatedItems);
      setPaletteLists(updatedPaletteLists);
  }
};

  


const handleClearCartonInPalette = (paletteIndex: number, cartonIndex: number) => {
  console.log('[cartonPalette.tsx] handleClearCartonInPalette - Clearing carton at palette index:', paletteIndex, 'and carton index:', cartonIndex);

  const updatedPaletteLists = [...paletteLists];
  console.log('[cartonPalette.tsx] handleClearCartonInPalette - Original palette lists:', updatedPaletteLists);

  const carton = updatedPaletteLists[paletteIndex].items[cartonIndex] as Carton;
  console.log('[cartonPalette.tsx] handleClearCartonInPalette - Carton to clear:', carton);

  const updatedItems = items.map((product) => {
      const itemInCarton = carton.items.find((item) => item.id === product.id);
      if (itemInCarton) {
          console.log('[cartonPalette.tsx] handleClearCartonInPalette - Restoring quantity for item:', product);
          return {
              ...product,
              ActualQuantity: product.ActualQuantity + itemInCarton.Quantity
          };
      }
      return product;
  });

  updatedPaletteLists[paletteIndex].items.splice(cartonIndex, 1);
  console.log('[cartonPalette.tsx] handleClearCartonInPalette - Updated palette lists after clearing:', updatedPaletteLists);

  setItems(updatedItems);
  setPaletteLists(updatedPaletteLists);
};

  
  const handleToggleCollapse = (index: number) => {
    setOpen(prevOpen => {
      const newOpen = [...prevOpen];
      newOpen[index] = !newOpen[index];
      return newOpen;
    });
  };
  const getNextCartonName = () => {
    let highestNumber = 0;
    cartonLists.forEach((carton) => {
      if (carton.name.startsWith('Carton #')) {
        const number = parseInt(carton.name.split('#')[1].trim());
        if (!isNaN(number) && number > highestNumber) {
          highestNumber = number;
        }
      }
    });
    paletteLists.forEach((palette) => {
      palette.items.forEach((carton) => {
        if ('name' in carton && carton.name.startsWith('Carton #')) {
          const number = parseInt(carton.name.split('#')[1].trim());
          if (!isNaN(number) && number > highestNumber) {
            highestNumber = number;
          }
        }
      });
    });
    return `Carton #${highestNumber + 1}`;
  };

  const handleAddReceivedData = useCallback(() => {
    console.log('[cartonPalette.tsx] handleAddReceivedData - Received data:', receivedData);
    if (!receivedData) {
      return;
    }

    const { id, RefTILOLI, codeEAN, quantity, destination, description, externalDocNo, ItemGrossWeight, Bin_Code } = receivedData;
    console.log('[cartonPalette.tsx] handleAddReceivedData - Extracted data - id:', id, 'RefTILOLI:', RefTILOLI, 'codeEAN:', codeEAN, 'quantity:', quantity, 'destination:', destination, 'description:', description, 'externalDocNo:', externalDocNo, 'ItemGrossWeight:', ItemGrossWeight, 'Bin_Code:', Bin_Code, 'RefTILOLI',RefTILOLI);
    if (!id || !RefTILOLI || !codeEAN || quantity === undefined || !destination) {
      return;
    }
  
    const newProduct: Product = {
      id: id,
      No: '',
      Code_EAN: codeEAN,
      RefTILOLI: RefTILOLI,
      Description: description,
      Quantity: quantity,
      ActualQuantity: 0,
      ItemGrossWeight: ItemGrossWeight,
      Status: '',
      externalDocNo: externalDocNo,
      Bin_Code: Bin_Code,
      Qty_to_Ship: quantity
    };
    console.log('[cartonPalette.tsx] handleAddReceivedData - New product:', newProduct);
  
    let cartonUpdated = false;
    let PaletteUpdate = false;
  
    const isPaletteDestination = paletteLists.some(palette => palette.name === destination);
    if (isPaletteDestination) {
      const paletteIndex = paletteLists.findIndex(palette => palette.name === destination);
      const palette = paletteLists[paletteIndex];
      if (palette && (palette.items.length === 0 || !palette.items.some(item => 'name' in item && item.name.startsWith("Produits sans carton")))) {
        handleAddProductUnderPalette(paletteIndex);
      }
      const updatedPalettes = paletteLists.map((palette, index) => {
        if (index === paletteIndex) {
          const updatedCartonsInPalette = palette.items.map(carton => {
            if ('name' in carton && carton.name.startsWith("Produits sans carton")) {              
              const existingItemIndex = carton.items.findIndex(item => item.id === id);
              if (existingItemIndex !== -1) {
                PaletteUpdate = false;
                return {
                  ...carton,
                  items: carton.items.map(item =>
                    item.id === id ? { ...item, Quantity: item.Quantity + quantity } : item
                  )
                };
              } else {
                PaletteUpdate = false;
                return { ...carton, items: [...carton.items, newProduct] };
              }
            }
            return carton;
          });
          return { ...palette, items: updatedCartonsInPalette };
        }
        return palette;
      });
      setPaletteLists(updatedPalettes);
    }
  
    const updatedPalettes = paletteLists.map(palette => {
      const updatedCartonsInPalette = palette.items.map(carton => {
        if ('name' in carton && carton.name === destination) {
          const existingItemIndex = carton.items.findIndex(item => item.id === id);
          if (existingItemIndex !== -1) {
            cartonUpdated = true;
            return {
              ...carton,
              items: carton.items.map(item =>
                item.id === id ? { ...item, Quantity: item.Quantity + quantity } : item
              )
            };
          } else {
            cartonUpdated = true;
            return { ...carton, items: [...carton.items, newProduct] };
          }
        }
        return carton;
      });
      return { ...palette, items: updatedCartonsInPalette };
    });
  
    if (cartonUpdated && !PaletteUpdate ) {
      console.log('[cartonPalette.tsx] handleAddReceivedData - Updated palette lists:', updatedPalettes);
      setPaletteLists(updatedPalettes);
    } else {
      
      const updatedCartons = cartonLists.map(carton => {
        
        if (carton.name === destination) {
          const existingItemIndex = carton.items.findIndex(item => item.id === id);
          if (existingItemIndex !== -1) {
            return {
              ...carton,
              items: carton.items.map(item =>
                item.id === id ? { ...item, Quantity: item.Quantity + quantity } : item
              )
            };
          } else {
            return { ...carton, items: [...carton.items, newProduct] };
          }
        }
        return carton;
      });
      console.log('[cartonPalette.tsx] handleAddReceivedData - Updated carton lists:', updatedCartons);
      setCartonLists(updatedCartons);
    }
    setReceivedData(null);
  }, [receivedData, cartonLists, paletteLists, setPaletteLists, setCartonLists, handleAddProductUnderPalette, setReceivedData]);
  
  

useEffect(() => {
    handleAddReceivedData();
});


const handleCartonClick = (carton: Carton, cartonIndex?: number, paletteIndex?: number) => {
  setSelectedCarton(carton);
  setFocusedElement({ paletteIndex: paletteIndex ?? null, cartonIndex: cartonIndex ?? null });
};


const handleAddScan = useCallback(
  (scannedCodeEAN: string, selectedCarton: Carton) => {
    console.log('[cartonPalette.tsx] handleAddScan - Scanned Code EAN:', scannedCodeEAN, 'Selected Carton:', selectedCarton);
    const scannedProduct = items.find(item => item.Code_EAN === scannedCodeEAN && item.ActualQuantity > 0);
    console.log('[cartonPalette.tsx] handleAddScan - Scanned Product:', scannedProduct);
    if (!scannedProduct) {
      setErrorModalMessage("Produit non trouvé dans la liste des articles ou quantité insuffisante.");
      playErrorSound();
      setModalOpen(true);
      return;
    }

    let cartonFound = false;
    let updatedItems = [...items];
    console.log('[cartonPalette.tsx] handleAddScan - Updated Items:', updatedItems);
    const updatedPalettes = paletteLists.map(palette => {
      const updatedCartonsInPalette = palette.items.map(item => {
        if ('name' in item && item.name === selectedCarton.name) {
          cartonFound = true;
          const updatedCartonItems = [...item.items];
          let foundExisting = false;

          for (let i = 0; i < updatedCartonItems.length; i++) {
            const cartonItem = updatedCartonItems[i];

            if (cartonItem.id === scannedProduct.id) {
              if (scannedProduct.ActualQuantity>0) {

                cartonItem.Quantity += 1;
                foundExisting = true;

                const scannedProductIndex = updatedItems.findIndex(item => item.id === scannedProduct.id && item.ActualQuantity > 0);
                if (scannedProductIndex !== -1) {
                  updatedItems[scannedProductIndex] = {
                    ...updatedItems[scannedProductIndex],
                    ActualQuantity: updatedItems[scannedProductIndex].ActualQuantity - 1,
                  };
                }
                break;
              }
            }
          }

          if (!foundExisting) {
            updatedCartonItems.push({
              ...scannedProduct,
              Quantity: 1,
              ActualQuantity: 0,
            });

            const scannedProductIndex = updatedItems.findIndex(item => item.id === scannedProduct.id && item.ActualQuantity > 0);
            if (scannedProductIndex !== -1) {
              updatedItems[scannedProductIndex] = {
                ...updatedItems[scannedProductIndex],
                ActualQuantity: updatedItems[scannedProductIndex].ActualQuantity - 1,
              };
            }
          }

          return { ...item, items: updatedCartonItems };
        }
        return item;
      });

      return { ...palette, items: updatedCartonsInPalette };
    });
    console.log('[cartonPalette.tsx] handleAddScan - Updated Palettes:', updatedPalettes);
    if (!cartonFound) {
      const updatedCartons = cartonLists.map(carton => {
        if (carton.name === selectedCarton.name) {
          const updatedCartonItems = [...carton.items];
          let foundExisting = false;

          for (let i = 0; i < updatedCartonItems.length; i++) {
            const cartonItem = updatedCartonItems[i];

            if (cartonItem.id === scannedProduct.id) {

              if (scannedProduct.ActualQuantity>0) {
                cartonItem.Quantity += 1;
                foundExisting = true;

                const scannedProductIndex = updatedItems.findIndex(item => item.id === scannedProduct.id && item.ActualQuantity > 0);
                if (scannedProductIndex !== -1) {
                  updatedItems[scannedProductIndex] = {
                    ...updatedItems[scannedProductIndex],
                    ActualQuantity: updatedItems[scannedProductIndex].ActualQuantity - 1,
                  };
                }
                break;
              }
            }
          }

          if (!foundExisting) {
            updatedCartonItems.push({
              ...scannedProduct,
              Quantity: 1,
              ActualQuantity: 0,
            });

            const scannedProductIndex = updatedItems.findIndex(item => item.id === scannedProduct.id && item.ActualQuantity > 0);
            if (scannedProductIndex !== -1) {
              updatedItems[scannedProductIndex] = {
                ...updatedItems[scannedProductIndex],
                ActualQuantity: updatedItems[scannedProductIndex].ActualQuantity - 1,
              };
            }
          }

          return { ...carton, items: updatedCartonItems };
        }
        return carton;
      });
      console.log('[cartonPalette.tsx] handleAddScan - Updated Cartons:', updatedCartons);
      setCartonLists(updatedCartons);
    } else {
      setPaletteLists(updatedPalettes);
    }

    setItems(updatedItems);
  },
  [items, paletteLists, cartonLists, setCartonLists, setItems, setPaletteLists]
);



useEffect(() => {
}, [cartonLists]);

useEffect(() => {
}, [paletteLists]);

const handleScanner = useCallback(
  (scannedCodeEAN: string) => {
    let cleanedCodeEAN = scannedCodeEAN.replace(/~/g, '');
    console.log('[cartonPalette.tsx] handleScanner Contenu du scan dans l\'input buffer:', scannedCodeEAN);
    if (!selectedCarton) {
      setErrorModalMessage("Aucun carton sélectionné.");
      playErrorSound();
      setModalOpen(true);
      console.error('Aucun carton sélectionné.');
      return;
    }
    if (!cleanedCodeEAN) {
      setErrorModalMessage("Code EAN non trouvé pour le texte scanné.");
      playErrorSound();
      setModalOpen(true);
      console.error('Code EAN non trouvé pour le texte scanné.');
      return;
    }

    if (cleanedCodeEAN.length < 13) {
      cleanedCodeEAN = cleanedCodeEAN.padStart(13, '0');
    } else if (cleanedCodeEAN.length > 13) {
      setErrorModalMessage("Le code EAN scanné dépasse 13 caractères.");
      playErrorSound();
      setModalOpen(true);
      console.error('[cartonPalette.tsx] handleScanner Le code EAN scanné dépasse 13 caractères.');
      return;
    }

    handleAddScan(cleanedCodeEAN, selectedCarton);
    
  },
  [selectedCarton, handleAddScan]
);
const inputBufferRef = useRef<string>('');
const MAX_BUFFER_WRITING_TIME = 500; 
const MIN_VALID_BUFFER_LENGTH = 13;
const MAX_VALID_BUFFER_LENGTH = 14;


const emptyInputBuffer = () => {
  inputBufferRef.current = '';
};

useEffect(() => {
  let lastKeyPressTime = Date.now();
  let bufferWritingStartTime = 0;

  const handleKeyPress = (event: KeyboardEvent) => {
    const { key } = event;
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastKeyPressTime;

    if (bufferWritingStartTime === 0) {
      bufferWritingStartTime = currentTime;
    }

    console.log(`Temps entre keypress précédent: ${elapsedTime} ms`);

    inputBufferRef.current += key;
    console.log('[cartonPalette.tsx] handleKeyPress Input buffer actuel:', inputBufferRef.current);

    if (key === '~') {
      if (selectedCarton && inputBufferRef.current.length > 0) {
        const bufferWritingTime = currentTime - bufferWritingStartTime;

        if (!(inputBufferRef.current.length >= MIN_VALID_BUFFER_LENGTH && inputBufferRef.current.length <= MAX_VALID_BUFFER_LENGTH)) {
          if (bufferWritingTime > MAX_BUFFER_WRITING_TIME) {
            console.log('[cartonPalette.tsx] handleKeyPress Temps d\'écriture du buffer trop long, effacement du buffer...');
            emptyInputBuffer();
            setErrorModalMessage("Il y avait déjà des caractères saisis au clavier. Veuillez rescanner le produit.");
            playErrorSound();
            setModalOpen(true);
          }
        } else {
          console.log('[cartonPalette.tsx] handleKeyPress - Buffer valide, longueur entre 12 et 13 caractères.');
          handleScanner(inputBufferRef.current);
          emptyInputBuffer();
        }
      }
    }

    lastKeyPressTime = currentTime;
  };

  window.addEventListener('keypress', handleKeyPress);

  return () => {
    window.removeEventListener('keypress', handleKeyPress);
  };
}, [selectedCarton, handleScanner]);





useEffect(() => {
  handleAddReceivedData();
}, [receivedData, handleAddReceivedData]);


const handleCloseModal = () => {
  setModalOpen(false);
  setErrorModalMessage('');
};



useEffect(() => {
  const { cartonLists, paletteLists } = allContent;

  const extractItems = (items: (Product | Carton)[], containerName: string) => {
      return items.flatMap(item => {
          if ('items' in item) {
              return item.items.map(subItem => ({
                  Code_EAN: subItem.Code_EAN,
                  Quantity: subItem.Quantity,
                  RefTILOLI: subItem.RefTILOLI,
                  Carton: item.name
              }));
          } else {
              return [{
                  Code_EAN: item.Code_EAN,
                  Quantity: item.Quantity,
                  RefTILOLI: item.RefTILOLI,
                  Carton: containerName
              }];
          }
      });
  };

  const totalItemsCount = [
    ...cartonLists.flatMap(carton => carton.items.map(item => item.Quantity)),
    ...paletteLists.flatMap(palette => extractItems(palette.items, palette.name).map(item => item.Quantity))
  ].reduce((acc, current) => acc + current, 0);

  setTotalItems(totalItemsCount);

  // const itemList = [
  //     ...cartonLists.flatMap(carton =>
  //         carton.items.map(item => ({
  //             Code_EAN: item.Code_EAN,
  //             Quantity: item.Quantity,
  //             RefTILOLI: item.RefTILOLI,
  //             Carton: carton.name
  //         }))
  //     ),
  //     ...paletteLists.flatMap(palette => extractItems(palette.items, palette.name))
  // ];

  // console.log(`Nombre total de produits présents en carton palette: ${totalItemsCount}`);
  // console.log('Liste des produits en carton palette:', itemList);
}, [allContent,setTotalItems]);

// useEffect(() => {
//   console.log('Current cartonLists state:', cartonLists);
// }, [cartonLists]);

const handleOpenWeightCartonModal = (cartonName: string) => {
  setCurrentCartonName(cartonName);
  const carton = cartonLists.find(c => c.name === cartonName);
  setModalWeight(carton?.totalPoidsCarton?.toString() || '');
  setOpenCartonModal(true);
};

const handleOpenWeightPaletteModal = (cartonName: string) => {
  setCurrentCartonName(cartonName);
  const paletteItem = paletteLists
    .flatMap(palette => palette.items)
    .find((item): item is Carton => 'name' in item && item.name === cartonName);
  
  setModalWeight(paletteItem?.totalPoidsCarton?.toString() || '');
  setOpenPaletteModal(true);
};



const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  setModalWeight(value.replace(/[^0-9.,]/g, ''));
};

const handleSaveWeightCarton = () => {
  if (currentCartonName !== null) {
    const weight = parseFloat(modalWeight.replace(',', '.'));

    setCartonLists(prevCartons => prevCartons.map(carton =>
      carton.name === currentCartonName
        ? { ...carton, totalPoidsCarton: weight }
        : carton
    ));
  }
  setOpenCartonModal(false);
};
const isCarton = (item: any): item is Carton => {
  return item && typeof item.name === 'string' && Array.isArray(item.items) && 'totalPoidsCarton' in item;
};

const handleSaveWeightPalette = () => {
  if (currentCartonName !== null) {
    const weight = parseFloat(modalWeight.replace(',', '.'));

    setPaletteLists(prevPalettes => prevPalettes.map(palette => ({
      ...palette,
      items: palette.items.map(item => {
        if (isCarton(item) && item.name === currentCartonName) {
          return { ...item, totalPoidsCarton: weight };
        }
        return item;
      })
    })));
  }
  setOpenPaletteModal(false);
};

const handlePoidsGlobalTotalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  setPoidsGlobalTotal(value === '' ? 0 : parseFloat(value));
};

  return (
    <div className="cartonlist">
      {error && (
        <Alert severity="error" style={{ margin: '10px 0', backgroundColor: '#ffdce0' }}>
          <Typography variant="body1" style={{ color: '#c62828' }}>{error}</Typography>
        </Alert>
      )}
<div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px', padding: '10px' }}>
  <Typography variant="body1" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
    Produit total dans carton/palette: {totalItems}
  </Typography>
</div>

<div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
    <Typography variant="body1" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
      Carton en cours: {selectedCarton?.name}
    </Typography>
  </div>

  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '5px', padding: '5px' }}>
  <Typography variant="body1" style={{ fontSize: '0.9rem', fontWeight: 'bold' }}>
    Poids Global Total:
  </Typography>
  <TextField
    type="number"
    value={poidsGlobalTotal || ''}  
    onChange={handlePoidsGlobalTotalChange}
    inputProps={{ 
      step: "0.01",
      style: { 
        fontSize: '0.9rem',
        padding: '2px 4px'  
      }
    }}  
    InputProps={{
      style: { 
        height: '25px',  
      }
    }}
    style={{ 
      width: '70px',
      margin: 0  
    }}
  />
</div>


  <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px', padding: '10px' }}>
  <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        onClick={handleAddCarton}
        startIcon={<AddIcon />}
        color="primary" 
      >
        Carton
      </Button>
    </ThemeProvider>
    <Button variant="contained" onClick={handleAddPalette} startIcon={<AddIcon />}>Palette</Button>
    {/* <Button variant="contained" onClick={() => handleGlobalPrint(allContent)} startIcon={<PrintIcon />}>Imprimer Tout</Button> */}
  </div>
      <div>
      {cartonLists.slice(0).reverse().map((carton, cartonIndex) => (
        
          <div key={cartonIndex} style={{ marginBottom: '10px' }} className="carton-item">
          <Typography
            onClick={() => handleCartonClick(carton, cartonIndex)}
            variant="h6"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div
          key={cartonIndex}
          ref={cartonIndex === cartonLists.length - 1 ? focusElementRef : null}
          tabIndex={0}
          style={{
            marginBottom: '10px',
            backgroundColor: selectedCarton && selectedCarton.name === carton.name ? '#1976D2' : 'transparent', 
          }}
          className="carton-item"
        >

              <span>{carton.name}</span> 

                   
                <IconButton onClick={() =>{ handleOpenProductModal(carton)}}>
                  <AddIcon />
                </IconButton>

                <IconButton onClick={() => handleCartonPrint(allContent, carton.name, items, user)}>
                  <PrintIcon />
                </IconButton>
                <IconButton onClick={() => handleOpenWeightCartonModal(carton.name)}>
                <ScaleIcon />
              </IconButton>
              <span style={{ marginLeft: '10px' }}>
                {carton.totalPoidsCarton !== undefined ? `Poids: ${carton.totalPoidsCarton} kg` : 'Poids: N/A'}
              </span>
              <IconButton onClick={() => openClearCartonModal(carton)}>
                <DeleteIcon />
            </IconButton>

                </div>
 
            </Typography>

            
            <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Référence</TableCell>
            <TableCell>Code EAN</TableCell>
            <TableCell>Quantité</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {carton.items.slice().reverse().map((item, itemIndex) => (
    <TableRow
      key={itemIndex}
      style={{
        backgroundColor:
          selectedCarton && selectedCarton.name === carton.name && itemIndex === 0 ? 'lightgreen' : 'inherit',
      }}
    >
      <TableCell title={`ID: ${item.id}`}>{item.RefTILOLI}</TableCell>
      <TableCell title={`Item No: ${item.No}`}>{item.Code_EAN}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>
      <IconButton onClick={() => openConfirmationModal(item, carton)}>
                <DeleteIcon />
      </IconButton>
      </TableCell>
    </TableRow>
  ))}
  {carton.items.length === 0 && (
    <TableRow>
      <TableCell colSpan={4} align="center">Aucun article</TableCell>
    </TableRow>
  )}
</TableBody>

      </Table>
    </TableContainer>
          </div>
        ))}
      </div>
  
      <div>
      {paletteLists.map((palette, paletteIndex) => (          
        <div key={paletteIndex} className="palette-container">
        <ListItem button onClick={() => handleToggleCollapse(paletteIndex)}>
        <Typography variant="h6">{palette.name}</Typography>
        {open[paletteIndex] ? <ExpandLess /> : <ExpandMore />}  
        <IconButton onClick={() => openClearPaletteModal(palette.name)}>
            <DeleteIcon />
        </IconButton>
        <IconButton onClick={() => handlePrintPalette(allContent,palette.name, items, user)}>
            <PrintIcon />
        </IconButton>
        </ListItem>

            <Collapse in={open[paletteIndex]} timeout="auto" unmountOnExit className="palette-collapse"> 
              <List component="div" disablePadding>
              {palette.items.map((carton, cartonIndex) => (
                              <div
                              key={cartonIndex}
                              style={{
                                marginBottom: '10px',
                                backgroundColor:
                                  'name' in carton && selectedCarton && selectedCarton.name === carton.name ? '#1976D2' : 'transparent',
                                filter: selectedCarton && selectedCarton.name === palette.name ? 'blur(4px)' : 'none',
                              }}
                              className="carton-item"
                            >
                  <ListItem  onClick={() => {
                      if ('name' in carton) {
                        handleCartonClick(carton, cartonIndex, paletteIndex);
                      }
                    }}>
                      <ListItemText primary={'name' in carton ? carton.name : ''} className="bold-text" />
                      <IconButton onClick={() => { handleOpenProductModal(carton as any); }}>
                        <AddIcon />
                      </IconButton>

                      <IconButton onClick={() =>handlePrintCartonUnderPalette(allContent,palette.name, cartonIndex, items, user)}>
                        <PrintIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpenWeightPaletteModal('name' in carton ? carton.name : '')}>
                <ScaleIcon />
              </IconButton>
              <span style={{ marginLeft: '10px' }}>
                {'totalPoidsCarton' in carton ? `Poids: ${carton.totalPoidsCarton} kg` : 'Poids: N/A'}
              </span>
              <IconButton onClick={() => openClearCartonInPaletteModal(paletteIndex, cartonIndex)}>
                <DeleteIcon />
            </IconButton>
                    </ListItem>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Référence</TableCell>
                            <TableCell>Code EAN</TableCell>
                            <TableCell>Quantité</TableCell>
                            <TableCell>Actions</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {'items' in carton && Array.isArray(carton.items) &&   carton.items.slice().reverse().map((item, itemIndex) => (
                              <TableRow
                                key={itemIndex}
                                style={{
                                  backgroundColor:
                                    selectedCarton && selectedCarton.name === carton.name && itemIndex === 0 ? 'lightgreen' : 'inherit',
                                }}
                              >
                              <TableCell>{item.RefTILOLI}</TableCell>
                              <TableCell>{item.Code_EAN}</TableCell>
                              <TableCell>{item.Quantity}</TableCell>
                              <TableCell>
                              <IconButton onClick={() => openConfirmationModal(item, carton)}>
                                <DeleteIcon />
                              </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                          {!('items' in carton) && (
                            <TableRow>
                              <TableCell colSpan={4} align="center">Aucun article</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ))}
              </List>
              <Button  variant="contained" onClick={() => handleAddCartonUnderPalette(paletteIndex)} startIcon={<AddIcon />} style={{ margin: '5px' }}>
                Ajouter Carton dans palette
              </Button>
              {!productListAdded[paletteIndex] && (
                <Button 
                  variant="contained" 
                  onClick={() => handleAddProductUnderPalette(paletteIndex)} 
                  startIcon={<AddIcon />} 
                  style={{ margin: '5px' }}
                >
                  Ajouter Produit dans palette
                </Button>
              )}
            </Collapse>
          </div>
        ))}
      </div>

      <Modal open={showClearCartonModal} onClose={() => setShowClearCartonModal(false)}>
          <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
              <Typography variant="h6">Confirmer la suppression du carton</Typography>
              <Typography>Êtes-vous sûr de vouloir supprimer ce carton ?</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <Button onClick={() => setShowClearCartonModal(false)}>Annuler</Button>
                  <Button onClick={() => {
                      cartonToClear && handleClearCarton(cartonToClear);
                      setShowClearCartonModal(false);
                  }} color="primary" variant="contained">
                      Supprimer
                  </Button>
              </div>
          </div>
      </Modal>

      <Modal open={showClearCartonInPaletteModal} onClose={() => setShowClearCartonInPaletteModal(false)}>
          <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
              <Typography variant="h6">Confirmer la suppression du carton</Typography>
              <Typography>Êtes-vous sûr de vouloir supprimer ce carton de la palette ?</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <Button onClick={() => setShowClearCartonInPaletteModal(false)}>Annuler</Button>
                  <Button onClick={() => {
                      handleClearCartonInPalette(paletteIndexToClear, cartonIndexToClear);
                      setShowClearCartonInPaletteModal(false);
                  }} color="primary" variant="contained">
                      Supprimer
                  </Button>
              </div>
          </div>
      </Modal>



      <Modal open={openCartonModal} onClose={() => setOpenCartonModal(false)}>
        <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
          <Typography variant="h6">Saisir le poids du carton</Typography>
          <TextField
            label="Poids"
            value={modalWeight}
            onChange={handleWeightChange}
            autoFocus
            fullWidth
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button onClick={() => setOpenCartonModal(false)}>Annuler</Button>
            <Button onClick={handleSaveWeightCarton} color="primary" variant="contained">Enregistrer</Button>
          </div>
        </div>
      </Modal>

      <Modal open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
          <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
              <Typography variant="h6">Confirmer la suppression</Typography>
              <Typography>Êtes-vous sûr de vouloir supprimer ce produit du carton ?</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <Button onClick={() => setShowConfirmationModal(false)}>Annuler</Button>
                  <Button onClick={() => itemToDelete && cartonSelectedToDelete && handleDelete(itemToDelete, cartonSelectedToDelete)} color="primary" variant="contained">Supprimer</Button>
              </div>
          </div>
      </Modal>

      <Modal open={showClearPaletteModal} onClose={() => setShowClearPaletteModal(false)}>
          <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
              <Typography variant="h6">Confirmer la suppression de la palette</Typography>
              <Typography>Êtes-vous sûr de vouloir supprimer cette palette ?</Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                  <Button onClick={() => setShowClearPaletteModal(false)}>Annuler</Button>
                  <Button onClick={() => {
                      handleClearPalette(paletteNameToClear);
                      setShowClearPaletteModal(false);
                  }} color="primary" variant="contained">
                      Supprimer
                  </Button>
              </div>
          </div>
      </Modal>



      <Modal open={openPaletteModal} onClose={() => setOpenPaletteModal(false)}>
        <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', width: '300px', marginTop: '10%' }}>
          <Typography variant="h6">Saisir le poids du carton</Typography>
          <TextField
            label="Poids"
            value={modalWeight}
            onChange={handleWeightChange}
            autoFocus
            fullWidth
          />
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button onClick={() => setOpenPaletteModal(false)}>Annuler</Button>
            <Button onClick={handleSaveWeightPalette} color="primary" variant="contained">Enregistrer</Button>
          </div>
        </div>
      </Modal>
  

            <Dialog open={openProductModal} onClose={handleCloseProductModal}>
                <DialogTitle>Ajouter un produit à {selectedList?.name}</DialogTitle>
                <DialogContent>
                  <TextField
                    label="Code EAN"
                    fullWidth
                    value={ean}
                    onChange={(e) => setEan(e.target.value)}
                    margin="normal"
                  />
                   <TextField
                    label="Quantité"
                    type="number"
                    fullWidth
                    value={quantity}
                    onChange={(e) => setQuantity(Number(e.target.value))}
                    margin="normal"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseProductModal} color="primary">
                    Annuler
                  </Button>
                  <Button onClick={handleAddProduct} color="primary">
                    Ajouter
                  </Button>
                </DialogActions>
              </Dialog>
              <ErrorModal
                open={modalOpen}
                message={errorModalMessage}
                onClose={handleCloseModal}
            />
            </div>
            );
            
};

export default CartonPalette;