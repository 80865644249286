import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

interface PdfOptions {
  filename: string;
  content: string;
}

const generatePdfWithPagination = async ({ filename, content }: PdfOptions): Promise<void> => {
  try {
    const element = document.createElement('div');
    element.innerHTML = content;
    document.body.appendChild(element); 


    const canvas = await html2canvas(element, { scale: 1 });
    const imgData = canvas.toDataURL('image/png');


    document.body.removeChild(element);


    const pdf = new jsPDF('p', 'mm', 'a4'); 
    const pageWidth = 210; 
    const pageHeight = 297; 
    const margin = 25; 
    const imgWidth = pageWidth - 2 * margin; 
    const imgHeight = canvas.height * imgWidth / canvas.width; 
    const maxPageHeight = pageHeight - 2 * margin; 

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, 'PNG', margin, position, imgWidth, maxPageHeight);
    heightLeft -= maxPageHeight;

 
    while (heightLeft > 0) {
      position -= maxPageHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', margin, position, imgWidth, maxPageHeight);
      heightLeft -= maxPageHeight;

    }


    const totalPages = pdf.getNumberOfPages();
    pdf.setFontSize(10);
    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);
      pdf.text(`Page ${i} / ${totalPages}`, pageWidth - 30, pageHeight - 10); // Position du numéro de page
    }


    pdf.save(filename);
  } catch (error) {
    console.error('Error generating PDF:', error);
  }
};

export default generatePdfWithPagination;
