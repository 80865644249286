import { Alert } from '@mui/material';
import Header from './Header';
import BLSection from './BlSection';
import ProductList from './Product';
import CartonPalette from './CartonPalette';
import './App.css';
import { useAppContext } from './AppContext';

const App = () => {
  const {
    showAlert, setShowAlert,
    alertMessage
  } = useAppContext();

  return (
    <div className='container'>
      <Header />
      <div className="content-wrapper">
        {showAlert && (
          <Alert severity="info" onClose={() => setShowAlert(false)} className="alert">
            {alertMessage}
          </Alert>
        )}
        <div className="content">
          <BLSection/>
          <div style={{ width: '1px', backgroundColor: 'gray' }}></div>
          <ProductList />
          <div style={{ width: '1px', backgroundColor: 'gray' }}></div>
          <CartonPalette/>
        </div>
      </div>
    </div>
  );
};

export default App;
